
// @ts-ignore
import { isRequired, isEmail, isMinLength } from "@/services/rule-services";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "Forgot",
	props: {},
	data: () => ({
		localLoading: false,
		email: "",
		valid: false,
		isEmailed: false,
		rules: { isRequired, isMinLength, isEmail },
		rulesField:{
            email: [],
        }
	}),
	created() {
		this.$nextTick(async () => {
			document.title = this.$t("forgotPassword.title");
		});
	},
	mounted() {},
	computed: {
		...mapGetters("proccess", ["isLoading"]),
	},
	components: {},
	methods: {
		...mapActions("auth", ["forgotPassword"]),

		async setLoading(load: Boolean = false) {
			this.localLoading = load;
		},

		async validate() {
			let form = this.$refs.form;
			return form.validate();
		},

		async addRules(){
            this.rulesField.email = [this.rules.isRequired, this.rules.isEmail];
        },

        async clearRules(){
            this.rulesField.email = [];
        }, 

		async handleSubmit() {
			try {
				await this.addRules();
				if (!(await this.validate())) return;
				this.setLoading(true);
				const response = await this.forgotPassword({
					email: this.email,
				});
				this.isEmailed =
					!!response?.message && String(response?.message).length > 0;
				this.setLoading(false);
				await this.clearRules();
			} catch (error) {
				this.setLoading(false);
				await this.clearRules();
			}
		},

		backToLogin() {
			this.$router.push("/auth/login");
		},
	},

	watch: {
		email(val: string) {
			if(val){
				this.rulesField.email = [this.rules.isRequired, this.rules.isEmail];
			}else{
				this.rulesField.email = [];
			}
		},
	},
};
